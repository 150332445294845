
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/dao/[symbol]",
      function () {
        return require("private-next-pages/dao/[symbol]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/dao/[symbol]"])
      });
    }
  